
import { defineComponent, ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "select-inspections-img-modal",
  components: {},
  props: {
    fileList: { type: Object, required: true },
    checkPoint: { type: String, required: true },
    checkboxFile: { type: Object, required: true },
  },
  emits: ["update-img-list"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const route = useRoute();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const addStoresModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);

    const formData = ref({
      checkbox: props.checkboxFile,
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          let checkbox_arr = [] as any[];
          formData.value.checkbox.forEach((element) => {
            let index = props.fileList.findIndex((item) =>
              item.id ? item.id == element : item.response.data[0].id == element
            );
            checkbox_arr.push({
              id: element,
              url: props.fileList[index].url,
            });
          });
          emit("update-img-list", {
            check_point: props.checkPoint,
            warehouse_quality_inspection_images_id: checkbox_arr,
          });
          loading.value = false;
          hideModal(addStoresModalRef.value);
        } else {
          return false;
        }
      });
    };
    const resetForm = () => {
      formRef.value?.resetFields();
    };
    const modalShowListener = (modalEl: HTMLElement | null, callback): void => {
      if (!modalEl) {
        return;
      }
      modalEl.addEventListener("show.bs.modal", callback);
    };

    onMounted(() => {
      modalShowListener(addStoresModalRef.value, () => {
        formData.value.checkbox = props.checkboxFile;
      });
    });

    watch(
      () => props.checkboxFile,
      (newV) => {
        formData.value.checkbox = newV;
      }
    );

    return {
      t,
      loading,
      formRef,
      addStoresModalRef,
      submitButton,
      formData,
      resetForm,
      submit,
      modalShowListener,
    };
  },
});
