
import {
  defineComponent,
  onMounted,
  watch,
  ref,
  onBeforeMount,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiBase, ApiQualityInspection } from "@/core/api";
import { Plus, Delete, ZoomIn } from "@element-plus/icons-vue";
import mixin from "@/mixins";
import _ from "lodash";
// import moment from "moment";
import JwtService from "@/core/services/JwtService";
import { CardProps } from "element-plus";
import selectImgModal from "@/views/documents/inspection-report/SelectInspectionsImgModal.vue";
// import { CarouselItem } from "element-plus/es/components/carousel/src/carousel";
import { useI18n } from "vue-i18n";
import FsLightbox from "fslightbox-vue/v3";
import {
  commonBackToList,
  setModuleBCN,
} from "@/core/directive/function/common";
import { checkPermissionFlag } from "@/directives/permission";

interface PhotoItem {
  id: string;
  url: string;
  check_point: any;
}
export default defineComponent({
  name: "documents-inspection-report-information",
  components: { Plus, Delete, ZoomIn, selectImgModal, FsLightbox },
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    // const imgTableData = ref([]);
    const dialogImageUrl = ref("");
    const uploadImage = ApiQualityInspection.uploadImage();
    const inspectionsUpload: any = ref<null | CardProps>(null);
    const fileList = ref<Array<PhotoItem>>([]);

    const formData = ref({
      stock_in_id: "",
      inventory_id: "",
      sku_identifier: "",
      identifier_code: "",
      warehouse: "",
      inspection_manager: "",
      inspection_status: 0,
      inspection_notes: "",
      // 吊牌、EAN、完整外观、正面、背面、配件、盒子
      inspections: { check_point: ref([] as any[]), data: [] as any[] },
      __show: {
        inspection_status: "",
        inspection_manager: "",
        warehouse: "",
      },
    });

    const addTableImgArr = ref({});

    const options = ref({
      identifier_code: "",
      warehouse: [],
      inspections: [],
      inspection_status: [
        {
          label: t("inspectionReport.failed"),
          value: 10,
        },
        {
          label: t("inspectionReport.passed"),
          value: 20,
        },
      ],
      toggler: false,
      sources: [] as any[],
      sourcesIndex: -1,
      sourcesSlide: 1,
    });

    const rules = ref({
      "inspections.data.notice": [
        {
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                value.trim() === "" &&
                formData.value.inspections.data[rule.field.split(".")[2]]
                  .result === 10
              ) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    // const getWarehouseData = async () => {
    //   const { data } = await ApiFulfillmentOrders.getWarehouseData({});
    //   if (data.code == 0) {
    //     options.value.warehouse = data.data;
    //   }
    // };

    const isFailStatus = computed(() => {
      let flag = false;
      const failIndex = formData.value.inspections.data.findIndex(
        (item) => item.result === 10
      );
      if (failIndex !== -1) {
        flag = true;
      }
      return flag;
    });

    const getShowInfo = async (isNew = false) => {
      if (isNew) loading.value = true;
      const { data } = await ApiQualityInspection.showQualityInspection({
        id: route.params.id,
      });
      if (isNew) loading.value = false;
      if (data.code == 0) {
        formData.value = data.data;
        options.value.identifier_code = data.data.identifier_code;
        var x = [] as any[];
        data.data.all_image.forEach((element) => {
          x.push({
            id: element.id,
            url: element.image,
            check_point: element.check_point,
            dialogVisible: false,
          });
        });
        fileList.value = x;
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["qa_image_tagging_list"],
      });
      if (data.code == 0) {
        options.value.inspections = data.data.qa_image_tagging_list.items;
      }
    };

    const getFromInfo = () => {
      loading.value = true;
      Promise.all([getShowInfo(), getTaggingData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          let params = formData.value;
          params.inspection_status = isFailStatus.value ? 10 : 20;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiQualityInspection.updateQualityInspection(params)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  fileList.value = [];
                  getShowInfo(true);
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });
    // 删除上传图片
    const handleRemove = async (file) => {
      if (inspectionsUpload.value) {
        inspectionsUpload.value.handleRemove(file);
        await ApiQualityInspection.deleteUploadImage({
          id: file.response
            ? (dialogImageUrl.value = file.response?.data[0].id)
            : (dialogImageUrl.value = file.id),
        });
        removerTableImg(
          file.response
            ? (dialogImageUrl.value = file.response?.data[0].id)
            : (dialogImageUrl.value = file.id)
        );
      }
    };

    const handlePreview = (file) => {
      // file.dialogVisible = true;
      // file.response
      //   ? (dialogImageUrl.value = file.response?.data[0].image)
      //   : (dialogImageUrl.value = file.url);
      let arr: string[] = [],
        number = 0;
      fileList.value.forEach((item, index) => {
        arr.push(item.url);
        if (item.id == file.id) {
          number = index;
        }
      });
      options.value.sources = arr;
      options.value.sourcesIndex = number;
      options.value.sourcesSlide = number + 1;
      options.value.toggler = !options.value.toggler;
    };

    const changeCheckPoint = (item) => {
      let { id, check_point, url, response } = item;
      if (id) {
        debounceCheckPoint(id, check_point, url);
      } else {
        debounceCheckPoint(
          response.data[0].id,
          check_point,
          response.data[0].image
        );
      }
    };

    const removerTableImg = (id) => {
      /*
        删除所有当前关联图片
      */
      formData.value.inspections.data.forEach((item) => {
        let index = item.warehouse_quality_inspection_images_id.findIndex(
          (item) => item.id == id
        );
        if (index >= 0) {
          item.warehouse_quality_inspection_images_id.splice(index, 1);
        }
      });
    };

    const debounceCheckPoint = _.debounce((id, check_point, url) => {
      let index,
        check_point_arr = [] as any[];
      addTableImgArr.value = {};
      removerTableImg(id);

      if (check_point.length > 0) {
        check_point.forEach((element) => {
          /* 获取图片所选的选项 对应的 表格行*/
          index = formData.value.inspections.check_point.indexOf(element);
          /* 大于0时 代表当前表格有需要的行 */
          if (index >= 0) {
            /*
              更新关联图片的所有td
            */
            formData.value.inspections.data[
              index
            ].warehouse_quality_inspection_images_id.push({
              id: id,
              url: url,
            });
          } else {
            /*
            小于0时 给表格添加新行
            上传图片模态框添加表格数据
          */
            check_point_arr.push(element);
            addTableImgArr.value[element] = {
              id: id,
              url: url,
            };
          }
        });
      }

      /*
       当check_point_arr有值时 更新 Inspections 数据 触发监听给表格添加带有图片的数据
      */
      if (check_point_arr.length > 0)
        formData.value.inspections.check_point =
          formData.value.inspections.check_point.concat(check_point_arr);
    }, 900);

    const getLabel = (val, key) => {
      return options.value[key]?.find((item) => {
        return item.value == val;
      })?.label;
    };

    const getArrDifference = (arr1, arr2) => {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
      });
    };

    // 变更表单
    const debounceUpdateTable = (nItem, oItem) => {
      if (Object.keys(addTableImgArr.value).length > 0) {
        Object.keys(addTableImgArr.value).forEach((element) => {
          formData.value.inspections.data.push({
            check_point: element,
            result: 10,
            notice: "",
            warehouse_quality_inspection_images_id: [
              {
                id: addTableImgArr.value[element].id,
                url: addTableImgArr.value[element].url,
              },
            ],
            __show: {
              check_point_label: getLabel(element, "inspections"),
            },
          });
        });
        addTableImgArr.value = {};
      } else if (nItem.length > oItem.length) {
        getArrDifference(nItem, oItem).forEach((element) => {
          formData.value.inspections.data.push({
            check_point: element,
            result: 10,
            notice: "",
            warehouse_quality_inspection_images_id: [],
            __show: {
              check_point_label: getLabel(element, "inspections"),
            },
          });
        });
      } else {
        if (nItem.length == 0) {
          // 清空
          formData.value.inspections.data = [];
          inspectionsUpload.value.uploadRef.fileList.forEach((element) => {
            element.check_point = [];
          });
        } else {
          getArrDifference(nItem, oItem).forEach((element) => {
            let index = formData.value.inspections.data.findIndex(
              (item) => item.check_point == element
            );
            formData.value.inspections.data.splice(index, 1);
          });
          inspectionsUpload.value.uploadRef.fileList.forEach((element) => {
            let index = (
              element.check_point
                ? element.check_point
                : element.response?.data[0].check_point
            ).indexOf(getArrDifference(nItem, oItem)[0]);
            if (index >= 0) {
              (element.check_point
                ? element.check_point
                : element.response?.data[0].check_point
              ).splice(index, 1);
            }
          });
        }
      }
    };

    // 初始化表格
    const addTable = (nItem) => {
      // console.log("初始化");
      // console.log(formData.value.inspections.data.length);
      if (formData.value.inspections.data.length == 0) {
        let table = [] as any[];
        nItem.forEach((element) => {
          table.push({
            check_point: element,
            result: 10,
            notice: "",
            warehouse_quality_inspection_images_id: [],
            __show: {
              check_point_label: getLabel(element, "inspections"),
            },
          });
        });
        formData.value.inspections.data = table;
      }
    };

    const updateImgList = (item) => {
      let tableIndex = formData.value.inspections.data.findIndex(
        (x) => x.check_point == item.check_point
      );

      // 更新上传列表 和 Inspections 关联关系
      console.log(item);
      let uploadImgIndex;
      inspectionsUpload.value.uploadRef.fileList.forEach((element, index) => {
        if (
          element.check_point
            ? element.check_point.indexOf(item.check_point) >= 0
            : element.response.data[0].check_point.indexOf(item.check_point) >=
              0
        ) {
          uploadImgIndex =
            item.warehouse_quality_inspection_images_id.findIndex(
              (x) =>
                x.id == (element.id ? element.id : element.response?.data[0].id)
            );
          if (uploadImgIndex < 0) {
            console.log(
              "当前图片有此标签,但是列表中已经被删除了,所以删除当前图片标签"
            );
            element.check_point
              ? element.check_point.splice(
                  element.check_point.indexOf(item.check_point),
                  1
                )
              : element.response.data[0].check_point.splice(
                  element.response.data[0].check_point.indexOf(
                    item.check_point
                  ),
                  1
                );
          }
        } else {
          uploadImgIndex =
            item.warehouse_quality_inspection_images_id.findIndex(
              (x) =>
                x.id == (element.id ? element.id : element.response?.data[0].id)
            );
          console.log(uploadImgIndex);
          if (uploadImgIndex >= 0) {
            console.log("当前图片没此标签,添加");
            element.check_point
              ? element.check_point.push(item.check_point)
              : element.response.data[0].check_point.push(item.check_point);
          }
        }
      });

      // 更新表格图片
      formData.value.inspections.data[
        tableIndex
      ].warehouse_quality_inspection_images_id =
        item.warehouse_quality_inspection_images_id;
    };

    const modalShowListener = (modalEl: HTMLElement | null, callback): void => {
      if (!modalEl) {
        return;
      }
      modalEl.addEventListener("show.bs.modal", callback);
    };

    watch(
      () => formData.value.inspections.check_point,
      (newValue, oldValue) => {
        console.log(oldValue);
        console.log(newValue);
        if (newValue.length >= 0 && oldValue.length != 0) {
          debounceUpdateTable(newValue, oldValue);
        } else {
          addTable(newValue);
        }
      }
    );

    const backToList = () => {
      commonBackToList(router, "/documents/inspection-report");
    };

    onBeforeMount(() => {
      getFromInfo();
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
    });

    return {
      t,
      checkPermissionFlag,
      loading,
      // imgTableData,
      options,
      formData,
      formRef,
      rules,
      submitButton,
      // dialogVisible,
      fileList,
      // fileListLocal,
      uploadImage,
      inspectionsUpload,
      addTableImgArr,
      uploadHeaders,
      dialogImageUrl,
      // handleChange,
      handleRemove,
      handlePreview,
      changeCheckPoint,
      submit,
      getLabel,
      updateImgList,
      modalShowListener,
      backToList,
    };
  },
});
